import React, { createContext, useEffect, useContext, useState } from "react";
import { RoleContext } from "../RoleProvider";
import { SecurityManagerType } from "src/types";
import { getBaseUrl } from "../../utils"
import { EmptyManager } from "../../constants";

// I wrote this thinking it would be a more testable replacement for the Regions and AORs part of the EntityAndManagerProvider.
// But I'm still running into issues with the unit test: when the region changes while binding an existing manager the AOR is not being
// updated, but only during the unit test.  I might stop using the selectedManager from this thing and just have the selected 
// manager be a prop to EditSecurityManagerModal.  degerste, Oct 2024

const initialState = {
    allManagers: [EmptyManager],
    aors: ["", ""], //the aors for whatever region is selected
    putChanges: async (_mgr: SecurityManagerType) => -1, 
    regionsAorsAndCountriesAreFetched: false,
    selectedRegion: "",
    selectedAOR: "",
    setIsEditing: (_isEditing: boolean) => {},
    updateRegion: (_region: string) => { },
};

const RegionsAorsCountriesContext = createContext(initialState);

const RegionsAorsCountriesProvider = (props: { children: JSX.Element }) => {
    const [isEditing, setIsEditing] = useState(true)//editing as opposed to creating a new manager
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [aors, setAors] = useState([""]);
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedAOR, setSelectedAOR] = useState("");
    const [allManagers, setAllManagers] = useState<SecurityManagerType[]>([EmptyManager]);
    const [AmerAORs, setAmerAORs] = useState<string[]>([""]);
    const [ApacAORs, setApacAORs] = useState<string[]>([""]);
    const [EmeaAORs, setEmeaAORs] = useState<string[]>([""]);
    const [regionsAorsAndCountriesAreFetched, setRegionsAorsAndCountriesAreFetched] = useState(false);


    const { loggedInUser, token } = useContext(RoleContext);
    const regionsEndpoint = `${getBaseUrl()}/regionsAorsCountries`;

    const fetchManagers = async () => {
        const managersUrl = `${getBaseUrl()}/managers`
        const res = await fetch(managersUrl,
            { headers: new Headers({ "wowie": token }) });
        if (res.status !== 200) {
            return;
        }
        const json = await res.json();
        setAllManagers(json.managers);
    }

    const fetchRegionsAorsAndCountries = async () => {
        const res = await fetch(regionsEndpoint,
            { headers: new Headers({ "wowie": token }) });
        if (res.status !== 200) {

            return;
        }
        const json = await res.json();
        const { data } = json;

        const amerAORs: string[] = [];
        const apacAORs: string[] = [];
        const emeaAORs: string[] = [];

        for (let i = 0; i < data.length; i++) {
            switch (data[i].region) {
                case "APAC":
                    if (!apacAORs.includes(data[i].aor)) {
                        apacAORs.push(data[i].aor);
                    }
                    break;
                case "AMER":
                    if (!amerAORs.includes(data[i].aor)) {
                        amerAORs.push(data[i].aor);
                    }
                    break;
                case "EMEA":
                    if (!emeaAORs.includes(data[i].aor)) {
                        emeaAORs.push(data[i].aor);
                    }
                    break;
            }
        }
        setAmerAORs(amerAORs);
        setApacAORs(apacAORs);
        setEmeaAORs(emeaAORs);
        setRegionsAorsAndCountriesAreFetched(true);
    }

    useEffect(() => {
        if (loggedInUser.length > 1) {
            fetchRegionsAorsAndCountries();
            fetchManagers();
        }

    }, [loggedInUser, refreshCounter]);

    //Update the AORs based on selected region
    const updateRegion = (region: string) => {
        setSelectedRegion(region);
        switch (region) {
            case "AMER":
                setAors(AmerAORs);
                break;
            case "EMEA":
                setAors(EmeaAORs);
                break;
            case "APAC":
                setAors(ApacAORs);
                break;
        }
    }

    const putChanges = async (mgr: SecurityManagerType) => {
        const res = await fetch(`${getBaseUrl()}/managers`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: isEditing ? "PUT" : "POST",
            body: JSON.stringify(mgr),
        });
        
        await res.json();
        if(res.status == 200){
            setRefreshCounter((old) => old + 1);
        }
        return res.status;
    };

    return (<RegionsAorsCountriesContext.Provider value={{
        allManagers,
        aors,
        putChanges,
        regionsAorsAndCountriesAreFetched,
        selectedAOR,
        selectedRegion,
        setIsEditing,
        updateRegion
    }}>
        {props.children}
    </RegionsAorsCountriesContext.Provider>);
}

export { RegionsAorsCountriesContext, RegionsAorsCountriesProvider }