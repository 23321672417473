import React, { createContext, useContext, useState, useEffect } from "react";
import { RoleContext } from "./RoleProvider";
import { CriticalityDefinitionsByRating, NumberToStringDictionary } from "../types";
import { getBaseUrl } from "../utils";

const initialState = {
   CriticalityDefinitions: {},
   RiskRatingDefinitions: {},
   EffectivenessDefinitions: {}
};

export type IdToNameAndDefinitionDictionary = {
   [key: number]: { name: string, definition: string }
};

export type DefinitionsContextType = {
   RiskRatingDefinitions: NumberToStringDictionary;
   EffectivenessDefinitions: IdToNameAndDefinitionDictionary;
   CriticalityDefinitions: CriticalityDefinitionsByRating;
}

export type EffectivenessDefinitionDBType = {
   id: number;
   name: string
}

export const DefinitionsContext = createContext<DefinitionsContextType>(initialState);


export const DefinitionsContextProvider = (props: { children: JSX.Element }) => {
   const [CriticalityDefinitions, setCriticalityDefinitions] = useState<CriticalityDefinitionsByRating>({});
   const [EffectivenessDefinitions, setEffectivenessDefinitions] = useState<IdToNameAndDefinitionDictionary>({});
   const [RiskRatingDefinitions, setRiskRatingDefinitions] = useState<NumberToStringDictionary>({});
   
   const { token } = useContext(RoleContext);

   useEffect(() => {
      const fxn = async () => {
         if (token.length > 0) {
            const response = await fetch(`${getBaseUrl()}/definitions`, { headers: new Headers({ "wowie": token }) });
            const json = await response.json();
            const { criticality_definitions, risk_measurement_definitions, effectiveness_definitions } = json;
            setCriticalityDefinitions(criticality_definitions);
            const riskToDefinitionDictionary: NumberToStringDictionary = {};
            for (let i = 0; i < risk_measurement_definitions.length; i++) {
               riskToDefinitionDictionary[risk_measurement_definitions[i].score] = risk_measurement_definitions[i].rating;
            }
            setRiskRatingDefinitions(riskToDefinitionDictionary);

            const effectivenessToDefinitionDictionary: IdToNameAndDefinitionDictionary = {};
            for (let i = 0; i < effectiveness_definitions.length; i++) {
               effectivenessToDefinitionDictionary[effectiveness_definitions[i].score] = {
                  name: effectiveness_definitions[i].name,
                  definition: effectiveness_definitions[i].description
               };
            }
            setEffectivenessDefinitions(effectivenessToDefinitionDictionary);
         }
         ;
      }
      fxn();
   }, [token]);
   return (<DefinitionsContext.Provider value={{ CriticalityDefinitions, RiskRatingDefinitions, EffectivenessDefinitions }}>{props.children}</DefinitionsContext.Provider>)
}